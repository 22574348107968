import React from "react"
// import PropTypes from "prop-types";
import Link from "../Link"
import Styles from "./Styles"

const ButtonLink = ({ label, size, ...props }) => {
  return (
    <Styles.ButtonLink size={size}>
      <Link {...props}>
        <Styles.ButtonLabel>{label}</Styles.ButtonLabel>
        <Styles.ButtonArrow>&rarr;</Styles.ButtonArrow>
      </Link>
    </Styles.ButtonLink>
  )
}

// ButtonLink.propTypes = {};

export default ButtonLink
