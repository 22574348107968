import styled from "styled-components"

const ButtonLabel = styled.span``

const ButtonArrow = styled.span`
  margin-left: ${({ theme }) => theme.space[1]};
  transition: all 100ms ease-in;
  /* padding-right: ${({ theme }) => theme.space[2]}; */
`

const ButtonLink = styled.div`
  border: ${({ theme }) => `1px solid ${theme.color.darkBrown}`};
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  min-width: ${({ theme }) => theme.space[9]};

  font-size: ${({ theme, size }) => {
    if (size === "s") return theme.fontSize[2]
    return theme.fontSize[3]
  }};

  a {
    padding: ${({ theme, size }) => {
      if (size === "s") return theme.space[2]
      return theme.space[2]
    }};
    display: block;
    text-decoration: none;
    width: 100%;
    height: 100%;
  }

  &:hover {
    ${ButtonArrow} {
      margin-left: ${({ theme }) => theme.space[2]};
    }
  }

  @media ${({ theme }) => theme.device.tablet} {
    min-width: ${({ theme }) => theme.space[10]};

    font-size: ${({ theme, size }) => {
      if (size === "s") return theme.fontSize[3]
      return theme.fontSize[4]
    }};

    border-width: 2px;

    a {
      padding: ${({ theme, size }) => {
        if (size === "s") return theme.space[2]
        return theme.space[3]
      }};
    }
  }

  @media ${({ theme }) => theme.device.laptop} {
    font-size: ${({ theme, size }) => {
      if (size === "s") return theme.fontSize[4]
      return theme.fontSize[5]
    }};
  }
`

export default {
  ButtonLink,
  ButtonLabel,
  ButtonArrow,
}
